<template>
  <div
    style="width: 100%; height: 80%;"
    class="my-12 d-flex align-center justify-center"
  >
    <v-progress-circular
      :width="5"
      indeterminate
      color="primary"
      size="80"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
